/**
 * This utility class permits the abstraction of methods
 * for reuse
 */
import imagemapjson from "../static/imagemap.json";

const imagemap = new Map(Object.entries(imagemapjson));

/**
   *
   * @param {The type of the enum - like GENDER or PARTY} enumType 
   * @param {The Key to the enum you are looking-up} entryKey
   * @returns The usable path and filename of the image found or <undefined>
   */
export default function getCharacteristicsImagePath(enumType,entryKey) {
    var useablePath;
    try {
      const mapOfCharacteristics = new Map(
        Object.entries(imagemap.get(enumType))
      );
      const relativePath = mapOfCharacteristics.get(entryKey);
      useablePath = process.env.PUBLIC_URL + relativePath;
    } catch {}
    return useablePath;
  }