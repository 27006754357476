

export const STRIPE_ID_KEY = "custom:stripeId";
export const STRIPE_SUB_EXPIRY_KEY = "custom:stripeSubExpiry";
export const COGNITO_EVENT_SIGNIN = "signIn";
export const COGNITO_EVENT_AUTOSIGNIN = "autoSignIn";


export function UserSignedIn(cognitoUser){
    try{
        if (cognitoUser["username"]!== null){
            return true;
        }else{
            return false;
        }
    }catch(error){
        return false;
    }
};
/*
export function UserSignedIn(){
    const { authStatus } = useAuthenticator(context => [context.authStatus]);
    console.log ('Auth Status in USU is ', authStatus);
    try {
      return authStatus === "authenticated";
    } catch (error) {
  //    console.log(error);
      return false;
    }
  };
  */
  
  /**
   * This method determines if the current user is NOT eligible to subscribe
   * Sounds like odd logic but we use this to disable the subscription menu item
   * Criteria:
   *  User is not logged in OR
   *  User is logged in and subscription is active
   */
  export const NotEligibleToSubscribe = (cognitoUser) => {
    const sisa = UserSignedInAndSubscriptionActive(cognitoUser);
    const nsi = !UserSignedIn(cognitoUser);
    return sisa || nsi;
  };
  
  export function UserSignedInAndSubscriptionActive(cognitoUser){ 
    try {
      const sa = IsUserSubscriptionActive(cognitoUser);
      const usi = UserSignedIn(cognitoUser);
      console.log(`sa is  ${sa} and usi is ${usi}`);
      return sa && usi;
    } catch (error) {
      //console.log(error);
      return false;
    }
  };
  
  export function UserSignedInAndSubscriptionInactive(cognitoUser){
    try {   
      const sa = IsUserSubscriptionActive(cognitoUser);
      const usi = UserSignedIn(cognitoUser);
      console.log(`sa is  ${sa} and usi is ${usi}`);
      return usi && !sa;
    } catch (error) {
  //    console.log(error);
      return false;
    }
  };
  
  export function UserIsStripeRegistered(attributes){
    try {
        if ( attributes === undefined){
            return false;
          }
          const isRegistered = attributes.hasOwnProperty(STRIPE_ID_KEY);
          return isRegistered;
    } catch (error) {
    //  console.log(error);
      return false;
      
    }
  };
  
  export function GetStripeAccount(attributes) {
    try {     
      const stripeId = attributes.get(STRIPE_ID_KEY);
      return stripeId;
    } catch (error) {
      return null;
    }
  };
  
  
  export function IsUserSubscriptionActive (cognitoUser){
    if ( cognitoUser === undefined){
      return false;
    }
      const expiryDate = cognitoUser.attributes[STRIPE_SUB_EXPIRY_KEY];
    const isActive = expiryDate > new Date();
    console.log ("Expiry Date Active ", isActive);
    return isActive;
  };
  