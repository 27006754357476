import React, { createContext, useState } from "react";

export const ApplicationContext = createContext();

export const ApplicationProvider = (props) => {
  
  const data = window.localStorage.getItem("ENUMMAP_KEY");
  const arr = JSON.parse(data);
  const characteristicsMap = new Map();
  try{
    if (arr.length>0 ) {
      arr.forEach((element) => {
        const key = element[0];
        const value = element[1];
        characteristicsMap.set(key, value);
      });
      console.log("Got enumMap details from localStorage", characteristicsMap);
    }
  }catch(error){
    
  }
  

  const [enumMap, setEnumMap] = useState(characteristicsMap);

  return (
    <ApplicationContext.Provider
      value={{
        enumMap,
        setEnumMap,
      }}
    >
      {props.children}
    </ApplicationContext.Provider>
  );
};
