import * as React from "react";

import Card from "@mui/material/Card";

import CardContent from "@mui/material/CardContent";
import { CardActionArea } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider} from '@mui/material/styles';
import getCharacteristicsImagePath from "../components/util/imageUtil";

export default function PersonCard({ person, active }) {
  const theme = createTheme({
    typography: {
      fontSize:10,
      
    },
  });

  if (person.length === 0) {
    return "";
  } else {
    return (
      <Card sx={{ width: 150, maxHeight: 250 }}>
        <CardActionArea
          id="cardactionarea"
          href={person.uri}
          target="_blank"
          rel="noreferrer"
          disabled={!active}
        >
          
            <CardContent id="cardcontent">
              <table align="center">
                
                  <tr>
                    <td>
                      <CardMedia
                        id="cardmedia"
                        component="img"
                        height="150"
                        image={person.image}
                        alt={person.external_id}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                    <ThemeProvider theme={theme}>
                      <Typography >
                        {person.first_name} {person.last_name}
                      </Typography>
                      </ThemeProvider>
                    </td>
                    
                  </tr>
                  </table>
                  <table align="center">
                  <tr align="left">
                    <td align="left" >
                    <Tooltip title={person.persontype}>
                      <CardMedia
                        key="PersonType"
                        component="img"
                        height="20"
                        width="20"
                        image={getCharacteristicsImagePath(
                          "PersonType",
                          person.persontype
                        )}
                        alt="PersonType"
                        sx={{ width: 20, maxHeight: 20 }}
                      />
                      </Tooltip>
                    </td>
                    <td align="left">
                    <Tooltip title={person.party}>
                      <CardMedia
                        key="Party"
                        component="img"
                        height="20"
                        width="20"
                        image={getCharacteristicsImagePath(
                          "Party",
                          person.party
                        )}
                        alt="Party"
                        sx={{ width: 20, maxHeight: 20 }}
                      />
                      </Tooltip>
                    </td>
                  </tr>
                
              </table>
            </CardContent>
        
        </CardActionArea>
      </Card>
    );
  }
}
