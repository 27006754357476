import React, { createContext, useState } from "react";

export const STRIPE_ID_KEY = "custom:stripeId";
export const STRIPE_SUB_EXPIRY_KEY = "custom:stripeSubExpiry";
export const COGNITO_EVENT_SIGNIN = "signIn";
export const COGNITO_EVENT_AUTOSIGNIN = "autoSignIn";

export const CognitoUserContext = createContext();

export const CognitoUserProvider = (props) => {
  const a = new Array([]);

  
  const [cognitoUser, setCognitoUser] = useState(a);

  return (
    <CognitoUserContext.Provider
      value={{
        cognitoUser,
        setCognitoUser,
      }}
    >
      {props.children}
    </CognitoUserContext.Provider>
  );
};
