import * as React from "react";

import { useContext } from "react";

import { API } from "aws-amplify";

import { DynamicPersonQueryTemplate } from "../graphql/slugqueries";
import { AndEnumFilterGenerator } from "../graphql/util/filterbuilder";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FaceRetouchingNaturalIcon from "@mui/icons-material/FaceRetouchingNatural";
import SelectedCharacteristics from "./SelectedCharcteristics";

import SignInManager from "../components/SignInManager.js";

import CharacteristicsSelector from "./CharacteristicsSelector";
import SlugSearchResults from "./SlugSearchResults";
import { ApplicationContext } from "../contexts/applicationcontext";
import { FilteredPeopleContext } from "../contexts/filteredpeoplecontext";
//import { AuthDetailsContextProvider } from "../contexts/authdetailscontext.js";

const drawerWidth = "auto";
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function SlugDrawer() {
  const { enumMap } = useContext(ApplicationContext); // Get the current value of the map of enums
  const myarr = Array.from(enumMap);
  const { setFilteredPeople } = useContext(FilteredPeopleContext);

  const [drawerOpen, setDrawerOpen] = React.useState(false);
React.useEffect(()=>{
  searchPeople(enumMap);
},[]
);
  //var originalEnumMap = new Map(enumMap); // try and keep a copy

  function handleDrawerOpen() {
    setDrawerOpen(true);
  }

  // This gets called whenever we close the drawer, good.
  // We could execute the searchPeople here
  const handleDrawerClose = () => {
    console.log("IN DRAWER", enumMap);
    window.localStorage.setItem("ENUMMAP_KEY", JSON.stringify(Array.from(enumMap)));
    console.log("Save map to localStorage", enumMap);
    searchPeople(enumMap);
    setDrawerOpen(false);
  };

  // Just close the drawer without updating search for people
  const handleDrawerQuit = () => {
    /**The below works but does not reset the radio buttons to the original map - visually confusing
     * You cant uncomment this until you know how to zap the current radiobutton selections inside the component enumselector
     * setEnumMap(originalEnumMap);
     *  */

    setDrawerOpen(false);
  };
  // Just clear the selection map updating search for people
  /**The below works but does not reset the radio buttons to the original map - visually confusing
   * You cant uncomment this until you know how to zap the current radiobutton selections inside the component enumselector
   * setEnumMap(originalEnumMap);
   *  */
  /*
  const handleDrawerClear = () => {
    //console.log ("Clearing All selections");
    const clearMap = new Map();
    setEnumMap(clearMap);
    //searchPeople(clearMap);
  };
  */

  async function searchPeople(map) {
    if (map===undefined){
      return;
    }
    const filter = AndEnumFilterGenerator(map);
    //console.log("Filter is ", filter);
    const queryTemplate = DynamicPersonQueryTemplate;
    var queryToExecute = "";

    if (map.size > 0) {
      queryToExecute = queryTemplate.replace("FILTER", filter);

      const apiData = await API.graphql({
        query: queryToExecute,
      });
      const peopleFromAPI = apiData.data.listPeople.items;
      //console.log("Got People in SlugDrawer");
      //      console.log(peopleFromAPI.listPeople);
      /*
    await Promise.all(
      peopleFromAPI.map(async (person) => {
                  console.log(person);
      })
    );
    */
      setFilteredPeople(peopleFromAPI);
    } else {
      setFilteredPeople(new Array([]));
    }
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <CssBaseline />
      <AppBar position="fixed" open={drawerOpen}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(drawerOpen && { display: "none" }) }}
          >
            <SearchIcon />
          </IconButton>
          <SelectedCharacteristics myarr={myarr}/>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 ,width:"200px"}}>
            Slughorn AI
          </Typography>

          <SignInManager />
        </Toolbar>
      </AppBar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={drawerOpen}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerQuit}>
            <ArrowBackIcon />
          </IconButton>
          <IconButton onClick={handleDrawerClose}>
            <FaceRetouchingNaturalIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <CharacteristicsSelector />
      </Drawer>
      <Main open={drawerOpen}>
        <DrawerHeader />
        <SlugSearchResults />
      </Main>
    </Box>
  );
}
