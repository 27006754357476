import { Auth } from "aws-amplify";
import { Hub } from "aws-amplify";

import { Authenticator } from "@aws-amplify/ui-react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useState, useEffect, useContext } from "react";
import * as React from "react";
import { View } from "@aws-amplify/ui-react";
import IconButton from "@mui/material/IconButton";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import Tooltip from "@mui/material/Tooltip";
import Fab from "@mui/material/Fab";
import {
  Typography,
  Modal,
  Box,
  Grid,
  Menu,
  MenuItem,
  Fade,
} from "@mui/material";

import Subscribe from "../payment/Subscribe.js";
import { CognitoUserContext } from "../contexts/cognitousercontext.js";
import {
  NotEligibleToSubscribe,
  UserSignedIn,
} from "../components/util/UserStatusUtil";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AUTHENTICATED = "authenticated";

export default function SignInManager() {
  //console.log ("TOP OF SIGNIN MANAGER");

  const { cognitoUser, setCognitoUser } = useContext(CognitoUserContext);

  //const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const { user, authStatus } = useAuthenticator((context) => [context.user]);
  // Wit this, user is 'undefined' or the full object, so we can use it here
  console.log("user", user);
  if (!getIsAuthenticated()) {
    setCognitoUser(user);
  }

  //console.log ("authStatus is ",authStatus);

  function getIsAuthenticated() {
    return authStatus === AUTHENTICATED;
  }

  function getUsername() {
    try {
      return cognitoUser["username"];
    } catch (error) {
      return null;
    }
  }
  function getInitial() {
    const username = getUsername();
    try {
      return username.substring(0, 1).toUpperCase();
    } catch (error) {
      return "?";
    }
  }

  hubAuthListener(); // Listener does not get any events during refresh

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);

  const [modalOpen, setModalOpen] = React.useState(false);
  // No more lines of procedural code execution in this script
  //console.log ("cognitoUser...", cognitoUser);
  //console.log("End of SIM method");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleModalOpen = () => {
    setModalOpen(true);
    handleMenuClose();
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  async function logOut() {
    handleMenuClose();
    setCognitoUser(null);
    try {
      await Auth.signOut({ global: true });
    } catch (error) {
      console.log("SIM-LogOut: error signing out: ", error);
    }
  }

  function hubAuthListener() {
    Hub.listen("auth", (data) => {
      //console.log("HUB SIM EVENT " + data.payload.event);
      //console.log("HUB EVENT IS USER AUTH'D?", getIsAuthenticated());

      switch (data.payload.event) {
        case "autoSignIn":
          handleModalClose();
          console.log(getUsername() + " auto-signed in");

          break;
        case "signIn":
          handleModalClose();

          console.log(getUsername() + " signed in");

          break;
        case "signUp":
          console.log("user signed up");

          break;
        case "signOut":
          console.log(getUsername() + " signed out");
          setCognitoUser(null);

          break;
        case "signIn_failure":
          console.log(getUsername() + " sign in failed");

          break;
        case "configured":
          console.log(" SH: the Auth module is configured");
          break;
        default:
          console.log("SH: Default triggered");
          console.log("Data is ", data);
          break;
      }
    });
  }
  return (
    <View>
      <IconButton
        sx={{ color: "white" }}
        id="basic-button"
        aria-controls={menuOpen ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? "true" : undefined}
        onClick={handleClick}
      >
        <Fab color="secondary" aria-label="add">
          <Tooltip title={getUsername()}>
            <Typography
              sx={{
                fontSize: 20,
              }}
            >
              {getInitial()}
            </Typography>
          </Tooltip>
        </Fab>
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={handleModalOpen}
          disabled={UserSignedIn(cognitoUser)}
        >
          <Tooltip title="Sign In/Up">
            <PersonOutlineIcon />
          </Tooltip>
        </MenuItem>
        <Subscribe disabled={NotEligibleToSubscribe(cognitoUser)} />
        <MenuItem disabled={!UserSignedIn(cognitoUser)} onClick={logOut}>
          <Tooltip title="Log Out">
            <LogoutIcon />
          </Tooltip>
        </MenuItem>
      </Menu>
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-end"
          >
            <IconButton
              sx={{ color: "white" }}
              id="basic-button"
              aria-controls={menuOpen ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={menuOpen ? "true" : undefined}
              onClick={handleModalClose}
            >
              <CloseIcon />
            </IconButton>
          </Grid>

          <Authenticator>
            {({ signOut, theUser }) => (
              <Fade in={modalOpen}>
                <Box sx={style}>
                  <Typography
                    id="spring-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Hi {getUsername()},
                  </Typography>

                  {"You can logout though ;)"}
                  <Tooltip title="Log Out Now?">
                    <IconButton sx={{ color: "red" }} onClick={signOut}>
                      <LogoutIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Fade>
            )}
          </Authenticator>
        </Grid>
      </Modal>
    </View>
  );
}
