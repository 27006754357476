
import "../App.css";

import * as React from "react";

import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";

import getCharacteristicsImagePath from "../components/util/imageUtil";

/**
 * The Generic Component
 * @param {String Representation of the enum typewe are trying to select}
 * @returns
 */
export default function SelectedCharacteristics({ myarr }) {
    if (myarr === undefined){
        return "";
    }
    
  return (
    <Grid container spacing={1}>
        {Array.from(myarr).map(([key, value]) => {
          return <CardMedia key={key}
          component="img"
          height="50"
          
          width="50"
          image={getCharacteristicsImagePath(key,value)}
          alt={key}
          sx={{ width: 30, maxHeight: 30, bgcolor:"white" }}
        />
      })}
    </Grid>
    )
}

// <Avatar alt="TEST" src={process.env.PUBLIC_URL + '/images/icons8-tick-60.png'} sx={{ width: 30, height: 30 }}/>
