import React from "react";
import { useState, useEffect, useContext } from "react";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import AWS from "aws-sdk";
import { Authenticator } from "@aws-amplify/ui-react";
import { ApplicationProvider } from "./contexts/applicationcontext";
import { FilteredPeopleProvider } from "./contexts/filteredpeoplecontext";
import { TriggerProvider } from "./contexts/triggercontext";
import { StripeProvider } from "./contexts/stripecontext";
import { SelectedCharacteristicsProvider } from "./contexts/selectedcharacteristicscontext";
import SlugQueryManager from "./appcomponents/SlugQueryManager";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Success from "./pages/Success";
import Cancel from "./pages/Cancel";
import SimpleBottomNavigation from "./SimpleBottomNavigation";

import { Auth } from "aws-amplify";

//import SlugHelper from "./SlugHelper.js";

export default function WrapperApp() {
  return (
    <div>
      <SelectedCharacteristicsProvider>
        <ApplicationProvider>
          <Authenticator.Provider>
            <StripeProvider>
              <TriggerProvider>
                <FilteredPeopleProvider>
                  <div className="App">
                    <SlugQueryManager />
                    {
                      // SlugHelper removed - only deploy for testing
                      //<SlugHelper />
                    }
                  </div>
                  <BrowserRouter>
                    <Routes>
                      <Route path="success" element={<Success />} />
                      <Route path="cancel" element={<Cancel />} />
                    </Routes>
                  </BrowserRouter>
                </FilteredPeopleProvider>
              </TriggerProvider>
            </StripeProvider>
            <SimpleBottomNavigation />
          </Authenticator.Provider>
        </ApplicationProvider>
      </SelectedCharacteristicsProvider>
    </div>
  );
}
