import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import config from "./aws-exports";

import WrapperApp from "./WrapperApp";
import {CognitoUserProvider} from "./contexts/cognitousercontext";
Amplify.configure(config);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
//  <React.StrictMode> ---Removed to stop multiple rerendering but presumably therewill be acost to this?
<CognitoUserProvider>
<WrapperApp/>
</CognitoUserProvider>
    
//  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
