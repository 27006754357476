import * as React from "react";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ArchiveIcon from "@mui/icons-material/Archive";
import CopyrightIcon from '@mui/icons-material/Copyright';
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export default function SimpleBottomNavigation() {
  const [value, setValue] = React.useState(0);

  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
       <Table>
        <TableBody>
        <TableRow>
            <TableCell align="center">
            <CopyrightIcon/>
            <Typography sx={{ fontSize: 6 }}>Copyright Liberty Communications Limited 2023</Typography>
            </TableCell>
        </TableRow>
        </TableBody>
       </Table>
      </BottomNavigation>
    </Paper>
  );
}
