import React, { createContext, useState } from "react";

export const StripeContext = createContext();

export const StripeProvider = (props) => {
    const stateMap = new Map();
    stateMap.set("INIT VALUE...", new Date());
  const [stripeData, setStripeData] = useState(stateMap);

  return (
    <StripeContext.Provider
      value={{
        stripeData,
        setStripeData
      }}
    >
      {props.children}
    </StripeContext.Provider>
  );
};
