import React, { createContext, useState } from "react";

export const SelectedCharacteristicsContext = createContext();

export const SelectedCharacteristicsProvider = (props) => {
    var initialValue = new Array([]);
    
  const [selectedCharacteristics, setSelectedCharacteristics] = useState(initialValue);

  return (
    <SelectedCharacteristicsContext.Provider
      value={{
        selectedCharacteristics,
        setSelectedCharacteristics,
      }}
    >
      {props.children}
    </SelectedCharacteristicsContext.Provider>
  );
};
