import React from "react";
import "../App.css";
import "@aws-amplify/ui-react/styles.css";
import { useContext } from "react";
import { View } from "@aws-amplify/ui-react";
import Grid from "@mui/material/Grid";

import { FilteredPeopleContext } from "../contexts/filteredpeoplecontext";
import { ApplicationContext } from "../contexts/applicationcontext";
import {
  UserSignedIn,
  UserSignedInAndSubscriptionActive,
} from "../components/util/UserStatusUtil";
import PersonCard from "./PersonCard";
import { CognitoUserContext } from "../contexts/cognitousercontext";

export default function SlugSearchResults() {
  const { filteredPeople } = useContext(FilteredPeopleContext);
  const { enumMap } = useContext(ApplicationContext);
  const { cognitoUser } = useContext(CognitoUserContext);
  //console.log("EMAP in SSR DEFAULT  is ", enumMap);
  const myarr = Array.from(enumMap);
  //console.log("COGNITOUSER IN SSR", cognitoUser);
  const stat = UserSignedIn(cognitoUser);
  //console.log("SSR USER SIGNEDIN ", stat);
  const signedInAndSubscriptionActive =
    UserSignedInAndSubscriptionActive(cognitoUser);
  const blur = signedInAndSubscriptionActive ? 0 : 8;
  const blurFilter = `blur(${blur}px)`;

  return (
    <View className="App">
      <View margin="3rem 0">
        <Grid container spacing={1} sx={{ filter: blurFilter }}>
          {filteredPeople.map(person => 
            <Grid key={person.external_id} item xs="auto">
              <PersonCard
                key={person.external_id}
                person={person}
                active={signedInAndSubscriptionActive}
              />
            </Grid>
          )
          }
        </Grid>
      </View>
    </View>
  );
}
